var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('h3',[_vm._v("School Fees Table")])])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('h4',[_vm._v("Pick a session to view your fees")]),_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Select Session","filterable":""},on:{"input":function($event){return _vm.fetchPaymentMonitor(true)}},model:{value:(_vm.form.sess_id),callback:function ($$v) {_vm.$set(_vm.form, "sess_id", $$v)},expression:"form.sess_id"}},_vm._l((_vm.sessions),function(session,index){return _c('el-option',{key:index,attrs:{"label":session.name,"value":session.id}})}),1)],1),(_vm.guardian)?_c('b-col',{attrs:{"cols":"4"}},[_c('h4',[_vm._v("Pick a Child")]),_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Select Term","filterable":""},on:{"input":function($event){return _vm.fetchPaymentMonitor(true)}},model:{value:(_vm.form.student_id),callback:function ($$v) {_vm.$set(_vm.form, "student_id", $$v)},expression:"form.student_id"}},_vm._l((_vm.guardian_students),function(guardian_student,index){return _c('el-option',{key:index,attrs:{"label":guardian_student.student.user.first_name +' ' + guardian_student.student.user.last_name,"value":guardian_student.student_id}})}),1)],1):_vm._e()],1),_c('el-alert',{attrs:{"closable":false,"type":"success"}},[_vm._v(" Click on the + sign on each row to view payment details ")]),_c('v-client-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"columns":_vm.columns,"options":_vm.options},scopedSlots:_vm._u([{key:"child_row",fn:function(ref){
var row = ref.row;
return [_c('aside',[_c('school-fee-payments',{attrs:{"currency":_vm.currency,"school-fee-payments":row.school_fee_payments},on:{"approve":function($event){return _vm.fetchPaymentMonitor(false)}}})],1)]}},{key:"student",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('strong',[_vm._v(_vm._s(row.student.user.first_name + ' ' + row.student.user.last_name))]),_c('br'),_c('span',[_vm._v(_vm._s(row.student.registration_no))])])}},{key:"total_fee",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm.currency + row.total_fee.toLocaleString())+" ")])}},{key:"amount_paid",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm.currency + row.amount_paid.toLocaleString())+" ")])}},{key:"balance",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm.currency + (parseFloat(row.total_fee - row.amount_paid)).toLocaleString())+" ")])}},{key:"action",fn:function(ref){
var row = ref.row;
return _c('div',{},[(parseFloat(row.total_fee - row.amount_paid) > 0 && (_vm.checkPermission(['can pay fees']) || _vm.checkRole(['parent'])))?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"gradient-primary"},on:{"click":function($event){return _vm.makePayment(row)}}},[_c('span',{staticClass:"align-middle"},[_vm._v("Make Payment")])]):_c('el-tag',{attrs:{"type":"success","effect":"dark"}},[_vm._v(" Paid ")])],1)}}]),model:{value:(_vm.payment_monitors),callback:function ($$v) {_vm.payment_monitors=$$v},expression:"payment_monitors"}}),(_vm.isPaymentSideBarActive)?_c('make-payment',{attrs:{"details":_vm.details,"currency":_vm.currency},on:{"save":_vm.updateTable},model:{value:(_vm.isPaymentSideBarActive),callback:function ($$v) {_vm.isPaymentSideBarActive=$$v},expression:"isPaymentSideBarActive"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }